<template>
  <div>
    <form class="w-100">
      <h3 class="card-title" v-if="profile">
        Manage Basic Information :
      </h3>
      <div class="candidate_info_wrapper">
        <div class="row">
          <div class="col-xl-12 mb-2">
            <ImageInsert />
            <has-error :form="form" field="photo"></has-error>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="father">Father's Name</label>
              <Input
                type="text"
                width="100%"
                v-model="form.father_name"
                placeholder="Father's Name"
                id="father"
              />
              <has-error :form="form" field="father_name"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="mother">Mother's Name</label>
              <Input
                type="text"
                width="100%"
                v-model="form.mother_name"
                placeholder="Mother's Name"
                id="mother"
              />
              <has-error :form="form" field="mother_name"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="Religion">Religion</label>
              <Select
                placeholder="Select your Religion"
                v-model="form.religion"
              >
                <Option
                  :value="item"
                  v-for="(item, index) in religion"
                  :key="index"
                  >{{ item }}</Option
                >
              </Select>
              <has-error :form="form" field="religion"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="blood_group">Blood Group</label>
              <Select
                placeholder="Select your Blood Group"
                v-model="form.blood_group"
              >
                <Option
                  :value="blood"
                  v-for="(blood, num) in blood_group"
                  :key="num"
                  >{{ blood }}</Option
                >
              </Select>
              <has-error :form="form" field="blood_group"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="date">Date Of Birth</label>
              <DatePicker
                type="date"
                v-model="form.dob"
                placeholder="Select date"
                class="w-100"
              ></DatePicker>
              <has-error :form="form" field="dob"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="nationality">Nationality</label>
              <Input
                type="text"
                width="100%"
                v-model="form.nationality"
                placeholder="Nationality"
                id="nationality"
              />
              <has-error :form="form" field="nationality"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="nid">National Identity Number(NID)</label>
              <Input
                type="text"
                width="100%"
                v-model="form.nid"
                placeholder="NID NO:"
                id="nid"
              />
              <has-error :form="form" field="nid"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="passport_number">Passport Number</label>
              <Input
                type="text"
                width="100%"
                v-model="form.passport_number"
                placeholder="Passport NO:"
                id="passport_number"
              />
              <has-error :form="form" field="passport_number"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="marital_status">Marital Status</label>
              <Select
                placeholder="Select your Marital Status"
                v-model="form.marital_status"
              >
                <Option
                  :value="marrige"
                  v-for="(marrige, sym) in marital_status"
                  :key="sym"
                  >{{ marrige }}</Option
                >
              </Select>
              <has-error :form="form" field="marital_status"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="secondary_phone"
                >Secondary Phone Number(If Any)</label
              >
              <Input
                type="number"
                width="100%"
                v-model="form.secondary_phone"
                placeholder="01XXXXXXXXX"
                id="secondary_phone"
              />
            </div>
            <has-error :form="form" field="secondary_phone"></has-error>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="secondary_email">Secondary Email Id (If Any)</label>
              <Input
                type="email"
                width="100%"
                v-model="form.secondary_email"
                placeholder="example@gmail.com"
                id="secondary_email"
              />
              <has-error :form="form" field="secondary_email"></has-error>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label for="Gender">Gender</label>
              <Select placeholder="Select your Gender" v-model="form.gender">
                <Option
                  :value="gen"
                  v-for="(gen, numeric) in gender"
                  :key="numeric"
                  >{{ gen }}</Option
                >
              </Select>
              <has-error :form="form" field="gender"></has-error>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group">
              <label for="secondary_email">Career Objective</label>
              <Input
                type="textarea"
                v-model="form.career_objective"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="Career Objective...."
              ></Input>
              <has-error :form="form" field="career_objective"></has-error>
            </div>
          </div>
          <div
            class="col-xl-12"
            v-for="(address, index) in form.addresses"
            :key="index"
          >
            <h4>{{ address.title }}</h4>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label for="Gender">Is Abroad</label>
                  <p>
                    <Checkbox
                      label="Is Abroad"
                      border
                      v-model="address.is_abroad"
                    >
                      &nbsp; Is Abroad</Checkbox
                    >
                  </p>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="Gender">Select Division</label>
                  <Select
                    placeholder="Select your Division"
                    v-model="address.division_id"
                    :disabled="address.is_abroad"
                    @on-change="getDistrict(index)"
                  >
                    <Option
                      :value="division.id"
                      v-for="(division, numeric) in divisions"
                      :key="numeric"
                      >{{ division.name }}</Option
                    >
                  </Select>
                  <small
                    v-if="
                      form.errors.has('addresses.' + index + '.division_id')
                    "
                    class="text-danger"
                    >This Division field is required
                  </small>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="Gender">Select District</label>
                  <Select
                    placeholder="Select your District"
                    :disabled="address.is_abroad"
                    v-model="address.district_id"
                    @on-change="getUpazila(index)"
                  >
                    <Option
                      :value="district.id"
                      v-for="(district, dis_index) in address.districts"
                      :key="dis_index"
                      >{{ district.name }}</Option
                    >
                  </Select>
                  <small
                    v-if="
                      form.errors.has('addresses.' + index + '.district_id')
                    "
                    class="text-danger"
                    >This District field is required
                  </small>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="Gender">Select Upazila</label>
                  <Select
                    placeholder="Select your Upazila"
                    :disabled="address.is_abroad"
                    v-model="address.upazila_id"
                  >
                    <Option
                      :value="upazila.id"
                      v-for="(upazila, upa_index) in address.upazilas"
                      :key="upa_index"
                      >{{ upazila.name }}</Option
                    >
                  </Select>
                  <small
                    v-if="form.errors.has('addresses.' + index + '.upazila_id')"
                    class="text-danger"
                    >This Upazilla field is required
                  </small>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="iso">Enter Country ISO</label>
                  <Input
                    type="text"
                    width="100%"
                    v-model="address.country_iso"
                    placeholder="Country Iso"
                    id="iso"
                  />
                </div>
              </div>
              <div class="col-xl-12">
                <div class="form-group">
                  <label for="Gender">Enter Your Address</label>
                  <Input
                    type="text"
                    placeholder="Insert Your Address"
                    v-model="address.address"
                  >
                  </Input>
                  <small
                    v-if="form.errors.has('addresses.' + index + '.address')"
                    class="text-danger"
                    >This Address field is required
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 text-right d-flex justify-content-between">
            <div>
              <router-link to="/profile" v-if="profile">
                <b-button
                    variant="primary" >
                  Back to profile
                </b-button>
              </router-link>
            </div>
            <div>
              <b-button
                  type="button"
                  variant="primary"
                  @click="save()"
                  :disabled="form.busy"
              >Save</b-button
              >
              <b-button
                  variant="primary"
                  class="ml-1"
                  v-if="showInfoLength > 0 && nextName"
                  @click="nextOnboard(nextName, nextTitle)"
              >
                Next
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import { BButton } from "bootstrap-vue";
import ImageInsert from "./partials/ImageInsert.vue";
export default {
  props: ["title", "nextOnboard", "nextTitle", "nextName", "description","profile"],

  data() {
    return {
      form: new Form({
        father_name: null,
        mother_name: null,
        religion: null,
        blood_group: null,
        dob: null,
        gender: null,
        nationality: null,
        nid: null,
        passport_number: null,
        marital_status: null,
        secondary_phone: null,
        secondary_email: null,
        career_objective: null,
        addresses: [
          {
            type: 1,
            title: "Present Address",
            address: null,
            division_id: null,
            district_id: null,
            upazila_id: null,
            country_iso: null,
            is_abroad: false,
            districts: {},
            upazilas: {},
          },
          {
            type: 2,
            title: "Permanent Address",
            address: null,
            division_id: null,
            district_id: null,
            upazila_id: null,
            country_iso: null,
            is_abroad: false,
            districts: {},
            upazilas: {},
          },
        ],
      }),

      religion: ["Islam", "Hinduism", "Christianity", "Buddhis", "Other"],
      blood_group: ["A+", "B+", "A-", "B-", "AB+", "AB-", "O+", "O-"],
      gender: ["Male", "Female", "Other"],
      marital_status: [
        "Single",
        "Married",
        "Widowed",
        "Divorced",
        "Separated",
        "Other",
      ],
      showInfoLength: null,
      divisions: [],
    };
  },
  mounted() {
    this.getDivision();
    this.showInfo()
  },
  methods: {
    getDistrict(index) {
      let address = this.form.addresses[index];
      axios
        .get("/candidate/district/get/" + address.division_id)
        .then((response) => {
          this.form.addresses[index].districts = response.data.districts;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getUpazila(index) {
      let address = this.form.addresses[index];
      axios
        .get("/candidate/upazilla/get/" + address.district_id)
        .then((response) => {
          this.form.addresses[index].upazilas = response.data.upazillas;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    save() {
      this.form
        .post("/candidate/info/update")
        .then((res) => {
          this.s(res.data.message);
          this.$store.dispatch("onboardData");
          this.showInfo();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDivision() {
      axios
        .get("/candidate/divisions/get")
        .then((response) => {
          this.divisions = response.data.divisions;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    showInfo() {
      axios
        .get("/candidate/get/info")
        .then((res) => {
          let data = res.data.data;
          let length = Object.values(data).filter((item)=> item !=null && Array.isArray(item) && item.length != 0).length;

          if (length > 0) {
            this.showInfoLength = length;
            this.form.father_name = data.father_name;
            this.form.mother_name = data.mother_name;
            this.form.religion = data.religion;
            this.form.blood_group = data.blood_group;
            this.form.dob = data.dob;
            this.form.gender = data.gender;
            this.form.nationality = data.nationality;
            this.form.nid = data.nid;
            this.form.passport_number = data.passport_number;
            this.form.marital_status = data.marital_status;
            this.form.secondary_phone = data.secondary_phone;
            this.form.secondary_email = data.secondary_email;
            this.form.career_objective = data.career_objective;

            let addresses = data.addresses;
            if (addresses.length > 0) {
              this.form.addresses = [];
              addresses.forEach((data, key) => {
                this.form.addresses.push({
                  type: data.type,
                  title: data.title,
                  address: data.address,
                  country_iso: data.country_iso,
                  is_abroad: data.is_abroad == true ? true : false,
                  division_id: data.division_id,
                  district_id: data.district_id,
                  upazila_id: data.upazila_id,
                  districts: {},
                  upazilas: {},
                });
                if (data.division_id != null) {
                  this.getDistrict(key);
                  this.getUpazila(key);
                }
              });
            }
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
  },
  components: {
    HasError,
    AlertError,
    BButton,
    ImageInsert,
  },
};
</script>
<style scoped>
.onboard-form .card-body {
  display: block !important;
}
</style>
