<template>
  <div>
    <form @submit.prevent="save()" @keydown="form.onKeydown($event)">
      <h3 class="card-title" v-if="profile">
        Manage Preference:
      </h3>
      <b-row>
        <b-col lg="4">
          <div class="form-group">
            <label>Designation*</label>
            <Input
              v-model="form.designation"
              placeholder="Enter Designation"
              style="width: 100%"
              type="text"
            />
            <has-error :form="form" field="designation"></has-error>
          </div>
        </b-col>

        <b-col cols="12" lg="4">
          <div class="form-group">
            <label>Seniority*</label>
            <Select
              placeholder="Select your Seniority"
              v-model="form.seniority"
            >
              <Option
                v-for="item in seniority"
                :value="item.name"
                :key="item.name"
                >{{ item.name }}
              </Option>
            </Select>
            <has-error :form="form" field="seniority"></has-error>
          </div>
        </b-col>

        <b-col cols="12" lg="4">
          <div class="form-group">
            <label>Workplace Type*</label>
            <Select
              placeholder="Select your Workplace Type"
              v-model="form.workplace_type"
            >
              <Option
                v-for="item in workplace_type"
                :value="item.name"
                :key="item.name"
                >{{ item.name }}
              </Option>
            </Select>
            <has-error :form="form" field="workplace_type"></has-error>
          </div>
        </b-col>

        <b-col cols="12" lg="4">
          <div class="form-group">
            <label>Availability*</label>
            <Select
              placeholder="Select your Availability"
              v-model="form.availability"
            >
              <Option
                v-for="item in availability"
                :value="item.name"
                :key="item.name"
                >{{ item.name }}
              </Option>
            </Select>
            <has-error :form="form" field="availability"></has-error>
          </div>
        </b-col>

        <b-col cols="12" lg="4">
          <div class="form-group">
            <label>Current Salary</label>
            <InputNumber
              v-model="form.current_salary"
              placeholder="Years Of Experience"
              style="width: 100%"
              type="number"
              :min="0"
            />
            <has-error :form="form" field="current_salary"></has-error>
          </div>
        </b-col>

        <b-col cols="12" lg="4">
          <div class="form-group">
            <label>Expected Salary*</label>
            <InputNumber
              v-model="form.expected_salary"
              placeholder="Years Of Experience"
              style="width: 100%"
              type="number"
              :min="0"
            />
            <has-error :form="form" field="expected_salary"></has-error>
          </div>
        </b-col>

        <b-col cols="12" lg="12">
          <div class="form-group">
            <label>Select Industries*</label>
            <Select
              v-model="form.industry_id"
              multiple
              filterable
              @on-change="checkIndustry"
            >
              <Option
                v-for="(industry, index) in industries"
                :key="index"
                :value="industry.id"
                v-if="industries.length"
                >{{ industry.name }}
              </Option>
            </Select>
            <has-error :form="form" field="industry_id"></has-error>
          </div>
        </b-col>
      </b-row>

      <div class="w-100 text-right">
        <b-row class="pt-1">
          <b-col class="d-flex justify-content-between" cols="4" lg="6">
            <b-button
              v-if="preferencesLength > 0 && !profile"
              variant="primary"
              @click="nextOnboard(nextName, nextTitle)"
            >
              Next
            </b-button>
            <router-link to="/profile" v-if="profile">
              <b-button variant="primary" class="ml-1">
                Back to profile
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="8" lg="6">
            <b-button variant="primary" type="submit" :disabled="form.busy">
              Save
            </b-button>
          </b-col>
        </b-row>
      </div>
    </form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  props: [
    "title",
    "nextOnboard",
    "nextTitle",
    "nextName",
    "description",
    "profile",
  ],
  data() {
    return {
      form: new Form({
        designation: null,
        seniority: null,
        workplace_type: null,
        availability: null,
        current_salary: null,
        expected_salary: null,
        industry_id: [],
      }),
      seniority: [
        { name: "ENTRY" },
        { name: "MID" },
        { name: "SENIOR" },
        { name: "MANAGER" },
      ],
      workplace_type: [
        { name: "REMOTE" },
        { name: "ONSITE" },
        { name: "HYBRID" },
      ],
      availability: [
        { name: "UNAVAILABLE" },
        { name: "FULLTIME" },
        { name: "PARTTIME" },
        { name: "HOURLY" },
        { name: "CONTRACTUAL" },
      ],
      preferences: {},
      industries: [],
      preferencesLength: null,
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  mounted() {
    this.showPreference();
    this.getIndustries();
  },
  methods: {
    checkIndustry() {
      if (this.form.industry_id.length >= 4) {
        this.form.industry_id.pop();
        this.i("The number of industry must not be greater than 3!");
      }
    },
    showPreference() {
      axios
        .get("/candidate/preference/show")
        .then((res) => {
          this.form.industry_id = [];
          res.data.industries.forEach((data) => {
            this.form.industry_id.push(data.id);
          });
          this.preferencesLength = Object.keys(res.data.preferences).length;
          this.preferences = res.data.preferences;
          this.form.designation = res.data.preferences.designation;
          this.form.seniority = res.data.preferences.seniority;
          this.form.workplace_type = res.data.preferences.workplace_type;
          this.form.availability = res.data.preferences.availability;
          this.form.current_salary = res.data.preferences.current_salary;
          this.form.expected_salary = res.data.preferences.expected_salary;
        })
        .catch((e) => {

          this.e(e.data.error);
        });
    },
    getIndustries() {
      axios
        .get("/candidate/industries")
        .then((res) => {
          this.industries = res.data.industries;
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },
    save() {
      this.form
        .post("/candidate/preference/update")
        .then((response) => {
          this.s(response.data.message);
          this.showPreference();
          this.$store.dispatch("onboardData");
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },
  },
};
</script>

<style scoped>
.onboard-form .card-body {
  display: block !important;
}

.experience-card {
  background-color: #fafafa;
}

.designation {
  color: #0c389f;
}
</style>
